import React from "react";
import {
  Paper,
  withStyles,
  Grid,
  TextField,
  Button,
  //FormControlLabel,
  //Checkbox,
  CircularProgress,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Face, Fingerprint } from "@material-ui/icons";
import loginBackground from "../assets/images/login-background.jpg";
import { authService } from "./authService.js";
import { Alert } from "reactstrap";
import Swal from "sweetalert2";

const styles = (theme) => ({
  margin: {
    margin: theme.spacing.unit * 2,
  },
  padding: {
    padding: theme.spacing.unit,
  },
});

class LoginTab extends React.Component {
  state = {
    loading: false,
    open: false,
    resetPassword: false,
    recoveryEmail: "",
    recovering: false,
  };

  async login() {
    let data = this.state;
    this.setState({ loading: true });
    let res = await authService.login(data);
    if (res) {
      let extension = "";
      if (res.data.role === "admin") {
        extension = "/dashboard";
      }
      if (res.data.role === "propietary") {
        extension = "/propietary/properties";
      }
      if (res.data.role === "applicant") {
        extension = "/applicant";
      }
      window.location.replace(
        `https://me.houm.com/authenticate/${res.data.token}?redirect_to=${extension}`
      );
    } else {
      this.setState({
        open: true,
        loading: false,
      });
    }
  }

  handleClose() {
    this.setState({ open: false });
  }

  alterState(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleClickOpen = () => {
    this.setState({
      resetPassword: true,
    });
  };

  render() {
    const { classes } = this.props;
    const { resetPassword, recoveryEmail, recovering } = this.state;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          backgroundSize: "cover",
          backgroundImage: `url("${loginBackground}")`,
        }}
      >
        <Dialog
          open={resetPassword}
          onClose={() => {
            this.setState({
              resetPassword: false,
            });
          }}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Recuperar contraseña</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ingresa el correo electrónico que usaste para ingresar a nuestra
              plataforma. Te enviaremos una clave temporal, la cual podrás
              cambiar una vez ingresado.
            </DialogContentText>
            <br />
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="ejemplo@houm.com"
              type="email"
              value={recoveryEmail}
              onChange={(e) => {
                this.setState({
                  recoveryEmail: e.target.value,
                });
              }}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.setState({
                  resetPassword: false,
                });
              }}
              color="primary"
            >
              Cancelar
            </Button>
            <Button
              onClick={async () => {
                this.setState({
                  recovering: true,
                  resetPassword: false,
                });
                if (this.state.recoveryEmail.length > 0) {
                  const recovered = await authService.recoverPassword(
                    recoveryEmail
                  );
                  if (recovered) {
                    this.setState({
                      recovering: false,
                    });
                    Swal.fire({
                      icon: "success",
                      title: "Contraseña reestablecida correctamente",
                      text:
                        "Revisa tu bandeja de correo electrónico para acceder a tu nueva contraseña, podrás cambiarla más tarde",
                    });
                  } else {
                    this.setState({
                      recovering: false,
                    });
                    Swal.fire({
                      icon: "error",
                      title: "Hubo un error",
                      text: "Comunícate con un ejecutivo",
                    });
                  }
                } else {
                  this.setState({
                    recovering: false,
                  });
                  Swal.fire({
                    icon: "warning",
                    title: "Ingresa una dirección de correo válida",
                  });
                }
              }}
              color="primary"
            >
              Recuperar
            </Button>
          </DialogActions>
        </Dialog>
        <Paper
          style={{
            maxWidth: "500px",
            width: "100%",
            maxHeight: "500px",
            margin: "0 auto",
          }}
          className={classes.padding}
        >
          <div className={classes.margin}>
            <Grid style={{ marginBottom: "10px" }} item>
              <h4 style={{ textAlign: "center" }}>Firmar Contratos Online</h4>
            </Grid>
            <br />
            <Grid container spacing={4} alignItems="flex-end">
              <Grid item>
                <Face />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  name="email"
                  onChange={(e) => this.alterState(e)}
                  id="email"
                  label="Correo"
                  type="email"
                  fullWidth
                  autoFocus
                  required
                />
              </Grid>
            </Grid>
            <Grid
              style={{ marginBottom: "20px" }}
              container
              spacing={4}
              alignItems="flex-end"
            >
              <Grid item>
                <Fingerprint />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  name="password"
                  onChange={(e) => this.alterState(e)}
                  id="username"
                  label="Contraseña"
                  type="password"
                  fullWidth
                  required
                />
              </Grid>
            </Grid>

            <Grid container alignItems="center" justify="space-between">
              <Grid item>
                {!recovering ? (
                  <Button
                    disableFocusRipple
                    disableRipple
                    style={{ textTransform: "none" }}
                    variant="text"
                    color="primary"
                    onClick={() => {
                      this.setState({
                        resetPassword: true,
                      });
                    }}
                  >
                    Recuperar contraseña
                  </Button>
                ) : (
                  <CircularProgress color="secondary" />
                )}
              </Grid>
            </Grid>
            <Grid container justify="center" style={{ marginTop: "10px" }}>
              {!this.state.loading ? (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => this.login()}
                  style={{ textTransform: "none" }}
                >
                  Ingresar
                </Button>
              ) : (
                <CircularProgress color="secondary" />
              )}
            </Grid>
          </div>
        </Paper>
        <Snackbar
          open={this.state.open}
          autoHideDuration={1500}
          onClose={() => this.handleClose()}
        >
          <Alert onClose={() => this.handleClose()} severity="error">
            No se pudo iniciar sesión
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default withStyles(styles)(LoginTab);
