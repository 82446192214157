import axios from "axios";
import { API_URL } from "../env";
import Swal from "sweetalert2";

class ContractService {
  // EXTRACT SIGNATURES FROM CARNET
  extractSignatures(files) {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    const http = axios.create({
      baseURL: API_URL,
      headers,
    });

    return http
      .post("/sign/generate-and-upload-signature-options-from-ci", files)
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error,
        });
        return false;
      });
  }

  // REMOVE UNUSED SIGNATURES

  removeUnusedSignatures(data) {
    const headers = {
      "Content-Type": "application/json",
    };
    const http = axios.create({
      baseURL: API_URL,
      headers,
    });

    return http.post("/s3-files/delete/", data).catch((error) => {
      Swal.fire({
        icon: "error",
        title: error,
      });
      return false;
    });
  }

  // SAVE CI FRONT AND BACK IMAGES
  saveCiFrontAndBack(formData, token) {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    const http = axios.create({
      baseURL: API_URL,
      headers,
    });

    return http.post("/sign/save-ci-and-signature", formData).catch((error) => {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: error,
      });
      return false;
    });
  }

  finalizeProcess(finalizeProcessFormData, token) {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    const http = axios.create({
      baseURL: API_URL,
      headers,
    });
    return http
      .post("/contracts/online-signing/finish", finalizeProcessFormData)
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error,
        });
        return false;
      });
  }

  verifySignatureToken(token, contractUid, signatureKey) {
    const headers = {
      "Content-Type": "application/json",
    };
    const http = axios.create({
      baseURL: API_URL,
      headers,
    });
    return http
      .post("/sign/contracts/verify-signature-token", {
        token,
        contract_uid: contractUid,
        signature_key: signatureKey,
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: error,
        });
        return false;
      });
  }

  getExistingSignature(token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const http = axios.create({
      baseURL: API_URL,
      headers,
    });

    return http.get("/sign/contract-person/signature-link").catch((error) => {
      console.log(error);
      return false;
    });
  }

  verifyValidToken(token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const http = axios.create({
      baseURL: API_URL,
      headers,
    });
    return http
      .post("/contracts/link/valid-token/")
      .then((response) => {
        return (response.data || {}).result === true;
      })
      .catch((error) => {
        return false;
      });
  }

  signatoryInfo(token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    const http = axios.create({
      baseURL: API_URL,
      headers,
    });
    return http.get("/contracts/signatory-info");
  }
}

export const contractService = new ContractService();
