import React, { useState } from "react";
import FormGroup from "@material-ui/core/FormGroup";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { contractService } from "../../services/contracts";
import Swal from "sweetalert2";
import LinearProgress from "@material-ui/core/LinearProgress";

const AcceptTerms = (props) => {
  const { selectedSignature, ciFront, ciBack } = props;
  const [signing, setSigning] = useState(false);
  const [signProof, setSignProof] = useState(false);
  const [loadingFinalizeProcess, setLoadingFinalizeProcess] = useState(false);
  const [state, setState] = useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
  });
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const finalizeProcess = async () => {
    if (loadingFinalizeProcess) return;
    setLoadingFinalizeProcess(true);

    let finalizeProcessFormData = new FormData();
    finalizeProcessFormData.append("sign_proof", signProof);

    return await contractService.finalizeProcess(
      finalizeProcessFormData,
      localStorage.getItem("token-owner"),
    );
  };

  const signAndProceed = async () => {
    setSigning(true);
    var formData = new FormData();
    formData.append("ci_front", ciFront);
    formData.append("ci_back", ciBack);
    formData.append("ci_signature_key", selectedSignature);
    const signed = await contractService.saveCiFrontAndBack(
      formData,
      localStorage.getItem("token-owner"),
    );
    if (signed) {
      Swal.fire({
        icon: "success",
        title: "¡Felicidades!",
        text: "Firma concretada",
      });
      await finalizeProcess();
      setSigning(false);
      props.trigger("next");
    } else {
      Swal.fire({
        icon: "error",
        title: "No se pudo concretar la firma",
        text: "Contáctate con un ejecutivo",
      });
      setSigning(false);
    }
  };
  const proceed = async () => {
    const notified = await finalizeProcess();
    if (notified) {
      Swal.fire({
        icon: "success",
        title: "¡Felicidades!",
        text: "Firma concretada",
      });
      props.trigger("next");
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Contáctese con su ejecutivo",
      });
    }
  };

  return !signing ? (
    <div>
      <h3>
        Este es el último paso, a continuación, si estás de acuerdo, aceptar las
        siguientes condiciones:
      </h3>
      <br />
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.checkedA}
              onChange={handleChange}
              name="checkedA"
              color="primary"
            />
          }
          label="He revisado los documentos."
        />
      </FormGroup>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.checkedB}
              onChange={handleChange}
              name="checkedB"
              color="primary"
            />
          }
          label="Estoy de acuerdo con las condiciones establecidas."
        />
      </FormGroup>
      <br />
      <FormGroup row>
        <h3>
          Valida tu identidad con una foto-selfie: necesitamos una fotografía
          donde se vea claramente tu rostro, sosteniendo tu documento de
          identidad. Esto nos ayuda a confirmar que eres tú.
        </h3>
        <p>La foto ingresada será revisada por un ejecutivo.</p>
        <div className="cropper-preview-wrap">
          <input
            type="file"
            accept="image/*"
            onChange={(e) => {
              setSignProof(e.target.files[0]);
            }}
          />
        </div>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.checkedC}
              onChange={handleChange}
              name="checkedC"
              color="primary"
            />
          }
          label="Confirmo que cumple con lo solicitado."
        />
      </FormGroup>
      <center>
        <Button
          variant="contained"
          color="primary"
          id="commence-process"
          disabled={
            loadingFinalizeProcess || !(state.checkedB && state.checkedA && state.checkedC && signProof)
          }
          onClick={() => {
            if (!selectedSignature) {
              proceed();
            } else {
              signAndProceed();
            }
          }}
          style={{ textTransform: "capitalize" }}
        >
          Firmar documentos
        </Button>
      </center>
    </div>
  ) : (
    <LinearProgress />
  );
};

export default AcceptTerms;
