import React, { useState, useRef, Fragment, useEffect } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";

import { homeownerService } from "../../services/homeowner";
import Swal from "sweetalert2";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const AgreementWrappers = (props) => {
  const { contractId } = props;
  const [pane, setPane] = useState(0);
  const [documents, setDocuments] = useState({});
  const [loading, setLoading] = useState(true);
  const [documentWidth, setDocumentWidth] = useState(0);
  const [numPages1, setNumPages1] = useState(null);

  const ref = useRef(null);

  useEffect(() => {
    const fetchDocuments = async () => {
      setLoading(true);
      const fetched = await homeownerService.retrieveContracts(contractId);
      if (fetched) {
        setDocuments(fetched.data.links);
        setLoading(false);
      } else {
        Swal.fire({
          icon: "error",
          title: "No se pudieron cargar los documentos",
          text: "Contáctate con tu ejecutivo",
        });
      }
    };
    fetchDocuments();
  }, [contractId]);

  useEffect(() => {
    setDocumentWidth(ref.current ? ref.current.offsetWidth - 50 : 0);
  }, [loading]);

  const dictionary = {
    contract_particular: {
      title: "Condiciones específicas contrato",
      nextButton: "Siguiente",
    },
    contract_general: {
      title: "Condiciones generales contrato",
      nextButton: "Acepto condiciones específicas y generales del contrato"
    },
    mandate_particular: {
      title: "Condiciones específicas contratos de recaudación y administración",
      nextButton: "Siguiente"
    },
    mandate_general: {
      title: "Contratos de recaudación y administración",
      nextButton: "Acepto condiciones específicas y generales del contrato"
    },
  }

  return !loading ? (
    <Fragment>
      <h3>Revisa los términos de los siguientes contratos</h3>
      <p style={{ fontSize: "1.1rem" }}>
        Si tienes cualquier duda, contacta a tu ejecutivo.
      </p>
      <Stepper activeStep={pane} orientation="vertical">
        {Object.keys(documents).map((key, index) => (
          <Step key={`landlord-files-${key}`}>
            <StepLabel style={{ cursor: "pointer" }} onClick={() => pane > index && setPane(index)}>{dictionary[key].title}</StepLabel>
            <StepContent>
              <center>
                <div id="general-term-document" ref={ref}>
                  <Document
                    file={documents[key]}
                    onLoadSuccess={({ numPages }) => setNumPages1(numPages)}
                  >
                    {
                      Array.from(
                        new Array(numPages1),
                        (el, index) => (
                          <>
                            <Page
                              key={`page_${index + 1}`}
                              pageNumber={index + 1}
                              width={documentWidth}
                            />
                            <br />
                          </>
                        ),
                      )
                    }
                  </Document>
                </div>
                <br />
                {pane > 0 && (
                  <Button
                    variant="contained"
                    color="secondary"
                    id="commence-process"
                    onClick={() => {
                      window.scroll(0, 0);
                      setPane(pane - 1);
                    }}
                    style={{ textTransform: "capitalize" }}
                  >
                    Anterior
                  </Button>
                )}
                {" "}
                <Button
                  variant="contained"
                  color="primary"
                  id="commence-process"
                  onClick={() => {
                    window.scroll(0, 0);
                    setPane(pane + 1);
                  }}
                  style={{ textTransform: "capitalize" }}
                >
                  {dictionary[key].nextButton}
                </Button>
              </center>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {pane === Object.keys(documents).length && (
        <center>
          <Button
            variant="contained"
            color="primary"
            id="commence-process"
            onClick={() => {
              props.trigger("next");
            }}
            style={{ textTransform: "capitalize" }}
          >
            Avanzar a firma de documentos
          </Button>
        </center>
      )}
    </Fragment>
  ) : (
    <Fragment>
      <h3>Cargando documentos...</h3>
      <LinearProgress color="secondary" />
    </Fragment>
  );
};

export default AgreementWrappers;
