import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import TenantInitializer from "../Tenant/pages/ProcessInitializer.jsx";
import HomeownerInitializer from "../Homeowner/pages/ProcessInitializer.jsx";
import LoginPage from "../Authentification/Login.jsx";
import SignedPage from "../SignedInformation/SignedPage.jsx";

const MainRouter = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <Route
          exact
          path="/lessee/:lessee_role/:auth_token"
          component={TenantInitializer}
        />

        <Route
          exact
          path="/lessor/:auth_token"
          component={HomeownerInitializer}
        />
        <Route
          exact
          path="/:token/:contract_uid/:signature_key"
          component={SignedPage}
        />
      </Switch>
    </Router>
  );
};

export default MainRouter;
