import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { contractService } from "../services/contracts";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./SignedPage.css";

const SignedPage = (props) => {
  const [loading, setLoading] = useState(true);
  const [information, setInformation] = useState({});
  let token = props.match.params.token;
  let contractUid = props.match.params.contract_uid;
  let signatureKey = props.match.params.signature_key;
  useEffect(() => {
    const prefetch = async () => {
      setLoading(true);
      const fetchedSignature = await contractService.verifySignatureToken(
        token,
        contractUid,
        signatureKey
      );
      if (fetchedSignature) {
        setInformation(fetchedSignature.data.signature_data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
    prefetch();
  }, [token, contractUid, signatureKey]);
  return (
    <div className="signed-page-wrapper">
      {loading ? (
        <CircularProgress />
      ) : (
        <div>
          <h4 style={{ fontWeight: "800 !important" }}>Nombre completo</h4>
          <h5>{information["name"]}</h5>
          <br />
          <h4>Número de Documento</h4>
          <h5> {information["document"]}</h5>
          <br />
          <h4>Documento de Identidad</h4>
          <img
            src={information["front_ci_link"]}
            style={{
              width: 320,
              height: "auto",
              objectFit: "contain",
              marginBottom: 40,
            }}
            alt=""
          />
          <h4>Firma</h4>
          <img
            src={information["signature_link"]}
            style={{ width: 320, height: "auto", objectFit: "contain" }}
            alt=""
          />
        </div>
      )}
    </div>
  );
};

export default withRouter(SignedPage);
