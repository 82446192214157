import React, { Fragment } from "react";

const SuccessPage = () => {
  return (
    <Fragment>
      <h3>
        Has terminado la firma con éxito.
        Te enviaremos los documentos a tu correo electrónico.
      </h3>
    </Fragment>
  );
};

export default SuccessPage;
