import React, { useState, useRef, Fragment } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Swal from "sweetalert2";
import { useEffect } from "react";
import { tenantService } from "../../services/tenant";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ContractWrappers = (props) => {
  const { contractId } = props;
  const [pane, setPane] = useState(0);
  const [documents, setDocuments] = useState({});
  const [loading, setLoading] = useState(true);
  const [documentWidth, setDocumentWidth] = useState(0);
  const [numPages1, setNumPages1] = useState(null);
  const [numPages2, setNumPages2] = useState(null);

  const ref = useRef(null);

  useEffect(() => {
    const fetchDocuments = async () => {
      setLoading(true);
      const fetched = await tenantService.retrieveContracts(contractId);
      if (fetched) {
        setDocuments(fetched.data.links);
        setLoading(false);
      } else {
        Swal.fire({
          icon: "error",
          title: "No se pudieron cargar los documentos",
          text: "Contáctate con tu ejecutivo",
        });
      }
    };
    fetchDocuments();
  }, [contractId]);

  useEffect(() => {
    setDocumentWidth(ref.current ? ref.current.offsetWidth - 50 : 0);
  }, [loading]);

  return !loading ? (
    <Fragment>
      <h3>Revisa los términos de tu contrato de arriendo</h3>
      <p style={{ fontSize: "1.1rem" }}>
        Si tienes cualquier duda, contacta a tu ejecutivo.
      </p>
      <Stepper activeStep={pane} orientation="vertical">
        <Step key="Selecciona/toma una foto de tu carnet">
          <StepLabel style={{ cursor: "pointer" }} onClick={() => {
            if (pane > 0) {
              setPane(0)
            }
          }}>
            Condiciones específicas contrato de arriendo
          </StepLabel>
          <StepContent>
            <center>
              <div id="general-term-document" ref={ref}>
                <Document
                  file={documents["contract_particular"]}
                  onLoadSuccess={({ numPages }) => setNumPages1(numPages)}
                >
                  {
                    Array.from(
                      new Array(numPages1),
                      (el, index) => (
                        <>
                          <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            width={documentWidth}
                          />
                          <br />
                        </>
                      ),
                    )
                  }
                </Document>
              </div>
              <br />
              <Button
                variant="contained"
                color="primary"
                id="commence-process"
                onClick={() => {
                  window.scroll(0, 0);
                  setPane(pane + 1);
                }}
                style={{ textTransform: "capitalize" }}
              >
                Siguiente
              </Button>
            </center>
          </StepContent>
        </Step>
        <Step key="Selecciona/toma una foto de tu carnet">
          <StepLabel style={{ cursor: "pointer" }} onClick={() => {
            if (pane > 1) {
              setPane(1)
            }
          }}>Condiciones generales contrato de arriendo</StepLabel>
          <StepContent>
            <center>
              <div id="general-term-document">
                <Document
                  file={documents["contract_general"]}
                  onLoadSuccess={({ numPages }) => setNumPages2(numPages)}
                >
                  {
                    Array.from(
                      new Array(numPages2),
                      (el, index) => (
                        <>
                          <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            width={documentWidth}
                          />
                          <br />
                        </>
                      ),
                    )
                  }
                </Document>
              </div>
              <br />
              <Button
                variant="contained"
                color="secondary"
                id="commence-process"
                onClick={() => {
                  window.scroll(0, 0);
                  setPane(pane - 1);
                }}
                style={{ textTransform: "capitalize" }}
              >
                Anterior
              </Button>
              {"  "}
              <Button
                variant="contained"
                color="primary"
                id="commence-process"
                onClick={() => {
                  setPane(pane + 1);
                  window.scroll(0, 0);
                }}
                style={{ textTransform: "capitalize" }}
              >
                Acepto condiciones específicas y generales del contrato
              </Button>
            </center>
          </StepContent>
        </Step>
      </Stepper>
      {pane === 2 && (
        <center>
          <Button
            variant="contained"
            color="primary"
            id="commence-process"
            onClick={() => {
              props.trigger("next");
            }}
            style={{ textTransform: "capitalize" }}
          >
            Continuar
          </Button>
        </center>
      )}
    </Fragment>
  ) : (
    <Fragment>
      <h3>Cargando documentos...</h3>
      <LinearProgress color="secondary" />
    </Fragment>
  );
};

export default ContractWrappers;
