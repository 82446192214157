import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { contractService } from "../services/contracts";
import CircularProgress from "@material-ui/core/CircularProgress";

const NewSignOrKeepOldOption = (
  {
    hasPreviousSignature,
    setHasPreviousSignature,
    signatoryName,
    trigger,
    party,
  }) => {
  const [previousSignatureUrl, setPreviousSignature] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const prefetchSignature = async () => {
      const token = party === "owner" ? localStorage.getItem("token-owner") : localStorage.getItem(`token-tenant${localStorage.getItem("lessee_role")}`);
      const fetchedSignature = await contractService.getExistingSignature(
        token || ""
      );
      if (fetchedSignature) {
        setHasPreviousSignature(true);
        setPreviousSignature(fetchedSignature.data.signature_link);
        localStorage.setItem("previous-signature-status-owner", "signed");
      } else {
        setHasPreviousSignature(false);
        localStorage.removeItem("previous-signature-status-owner");
      }
      setLoading(false);
    };

    prefetchSignature();
  }, [party, setHasPreviousSignature]);

  if (loading) return <CircularProgress />;
  if (!hasPreviousSignature)
    return (
      <>
        <h3>
          Genial{" "}
          <strong style={{ fontSize: "2rem", textDecoration: "underline" }}>
            {signatoryName}
          </strong>
          , ahora solo debes subir una foto de tu carnet por ambos lados
        </h3>
        <Button
          variant="contained"
          color="primary"
          id="commence-process"
          style={{ textTransform: "capitalize" }}
          onClick={() => {
            trigger("next");
          }}
        >
          Ir a subir archivos
        </Button>
      </>
    );
  return (
    <>
      <center>
        <h3>Ya hay una firma asociada a esta cuenta</h3>
        <p id="introduction-text">
          Puedes utilizar esta firma o ingresar una nueva.
        </p>
        <img
          src={previousSignatureUrl}
          style={{ height: 100, width: "auto", objectFit: "contain" }}
          alt="Firma actual"
        />
        <div>
          <Button
            variant="contained"
            color="primary"
            id="commence-process"
            style={{ textTransform: "capitalize" }}
            onClick={() => {
              trigger("next_2");
            }}
          >
            Utilizar firma actual
          </Button>
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            id="commence-process"
            style={{ textTransform: "capitalize" }}
            onClick={() => {
              trigger("next");
            }}
          >
            Subir nueva firma
          </Button>
        </div>
      </center>
    </>
  );
};

export default NewSignOrKeepOldOption;
