import React from "react";
import Button from "@material-ui/core/Button";
import "../styles/introduction.css";

const Introduction = (props) => {
  return (
    <div className="introduction-text-wrapper">
      <h3>
        Bienvenido <strong style={{ fontSize: "2rem", textDecoration: "underline" }}>{props.signatoryName}</strong> al proceso de firma online de los contratos necesarios para
        arrendar tu propiedad.
      </h3>
      <br />
      <p id="property-address">El proceso es el siguiente:</p>
      <ul style={{ fontSize: "1.3rem" }}>
        <li>Usted debe revisar y aprobar los contratos.</li>
        <li>Subir la foto de su carnet por ambos lados.</li>
        <li>Aprobar la firma online de los documentos.</li>
        <li>
          Los contratos quedarán a la espera de la firma de las otras partes (en caso que hayan más partes firmantes).
        </li>
        <li>
          Una vez estén firmados, se le enviará copia a su correo electrónico.
        </li>
      </ul>
      <center>
        <Button
          variant="contained"
          color="primary"
          id="commence-process"
          onClick={() => {
            props.trigger("next");
          }}
          style={{ textTransform: "capitalize" }}
        >
          Comenzar proceso de firma
        </Button>
      </center>
    </div>
  );
};

export default Introduction;
