import axios from "axios";
import { API_URL } from "../env";
import Swal from "sweetalert2";

class HomeownerService {
  // GET CONTRACT FILES OWNER
  retrieveContracts(contractUid) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token-owner")}`,
    };
    const http = axios.create({
      baseURL: API_URL,
      headers,
    });
    return http.get("/contracts/get-links").catch((error) => {
      Swal.fire({
        icon: "error",
        title: error,
      });
      return false;
    });
  }
}

export const homeownerService = new HomeownerService();
