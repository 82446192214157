import React from "react";

const SuccessPage = () => {
  return (
    <div>
      <h3>
        ¡Felicitaciones por encontrar tu nuevo Houm! Nos comunicaremos contigo
        cuando estemos listos.
      </h3>
    </div>
  );
};

export default SuccessPage;
