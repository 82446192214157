import { Container } from "reactstrap";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import queryString from "query-string";
import "../styles/process_initializer.css";
import Swal from "sweetalert2";

import Introduction from "../partials/Introduction.jsx";
import AgreementWrappers from "../partials/AgreementWrappers.jsx";
import SignatureCreation from "../partials/SignatureCreation.jsx";
import AcceptTerms from "../partials/AcceptTerms";
import SuccessPage from "../partials/SuccessPage";
import NewSignOrKeepOldOption from "../../SharedComponents/NewSignOrKeepOldOption.jsx";

import { contractService } from "../../services/contracts";

function getSteps() {
  return [
    "Introducción",
    "Revisar Contratos",
    /* "Revisar contratos",*/ "Firma",
  ];
}

const ProcessInitializer = (props) => {
  const steps = getSteps();
  const [activeStep, setActiveStep] = useState(0);
  const [contractId, setContractId] = useState("");
  const [selectedSignature, setSelectedSignature] = useState("");
  const [ciFront, setCiFront] = useState(null);
  const [ciBack, setCiBack] = useState(null);
  const [hasPreviousSignature, setHasPreviousSignature] = useState(false);
  const [tokenIsValid, setTokenIsValid] = useState(false);
  const [verifyTokenLoading, setVerifyTokenLoading] = useState(true);
  const [signatoryInfo, setSignatoryInfo] = useState(null);
  const [signatoryLoading, setSignatoryLoading] = useState(false);

  const params = useParams();

  const paneSwitchTrigger = (direction) => {
    window.scroll(0, 0);
    if (direction === "prev") {
      setActiveStep(activeStep - 1);
      window.history.replaceState(
        "",
        "",
        `/lessor/${params.auth_token}?step=${activeStep - 1}`,
      );
    }
    if (direction === "next") {
      window.history.replaceState(
        "",
        "",
        `/lessor/${params.auth_token}?step=${activeStep + 2}`,
      );
      setActiveStep(activeStep + 1);
    }
    if (direction === "next_2") {
      window.history.replaceState(
        "",
        "",
        `/lessor/${params.auth_token}?step=${activeStep + 4}`,
      );
      setActiveStep(activeStep + 2);
    }
  };

  const getSignatoryInfo = async (token) => {
    try {
      setSignatoryLoading(true);
      const response = await contractService.signatoryInfo(token);
      setSignatoryInfo(response.data);
    } catch {
      Swal.fire({
        icon: "error",
        title: "Error al buscar firmante",
        text: "No se pudo obtener la información del firmante",
      });
    } finally {
      setSignatoryLoading(false);
    }
  };

  const verifyValidToken = async (token) => {
    const validToken = await contractService.verifyValidToken(token);
    setTokenIsValid(validToken);
    setVerifyTokenLoading(false);

    if (!validToken) {
      Swal.fire({
        icon: "error",
        title: "Este link ha expirado",
        text: "Comunícate con tu ejecutivo para más información",
      });
    }
  };

  useEffect(() => {
    verifyValidToken(params.auth_token);
    getSignatoryInfo(params.auth_token);
    localStorage.setItem("token-owner", params.auth_token);
    setContractId(props.match.params.contract_id);
    window.scroll(0, 0);
    const values = queryString.parse(props.location.search);
    if (values.step) {
      setActiveStep(values.step - 1);
    }
  }, [
    props.location.search,
    params.auth_token,
    props.match.params.contract_id,
  ]);

  return (
    <div className="process-initializer-wrap">
      <Container className="process-initializer-content">
        {verifyTokenLoading || signatoryLoading ? (
          <Grid container justify="center" style={{ marginTop: "200px" }}>
            <CircularProgress color="secondary" />
          </Grid>
        ) : tokenIsValid ? (
          <>
            <div className="stepper-wrap">
              <Stepper alternativeLabel activeStep={activeStep}>
                {steps.map((label, i) => (
                  <Step
                    key={label}
                    className={i < activeStep && "hoverable"}
                    onClick={() => {
                      if (i < activeStep) {
                        window.history.replaceState(
                          "",
                          "",
                          `/lessor/${localStorage.getItem("token-owner")}?step=${i + 1}`,
                        );
                        setActiveStep(i);
                      }
                    }}
                  >
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            <div className="step-pane-wraps">
              {activeStep === 0 && (
                <Introduction
                  signatoryName={signatoryInfo?.name}
                  trigger={paneSwitchTrigger}
                />
              )}
              {activeStep === 1 && (
                <AgreementWrappers
                  trigger={paneSwitchTrigger}
                  contractId={contractId}
                />
              )}
              {activeStep === 2 && (
                <NewSignOrKeepOldOption
                  hasPreviousSignature={hasPreviousSignature}
                  setHasPreviousSignature={setHasPreviousSignature}
                  signatoryName={signatoryInfo?.name}
                  trigger={paneSwitchTrigger}
                  party="owner"
                />
              )}
              {activeStep === 3 && (
                <SignatureCreation
                  trigger={paneSwitchTrigger}
                  contractId={contractId}
                  setSelectedSignature={setSelectedSignature}
                  setCiBack={setCiBack}
                  setCiFront={setCiFront}
                  setHasPreviousSignature={setHasPreviousSignature}
                  signatoryName={signatoryInfo?.name}
                />
              )}
              {activeStep === 4 && (
                <AcceptTerms
                  trigger={paneSwitchTrigger}
                  contractId={contractId}
                  selectedSignature={selectedSignature}
                  ciBack={ciBack}
                  hasPreviousSignature={hasPreviousSignature}
                  ciFront={ciFront}
                  signatoryName={signatoryInfo?.name}
                />
              )}
              {activeStep === 5 && <SuccessPage />}
            </div>
          </>
        ) : (
          <Grid container justify="center" style={{ marginTop: "10px" }}>
            <p>Link expirado</p>
          </Grid>
        )}
      </Container>
    </div>
  );
};

export default ProcessInitializer;
