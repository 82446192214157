import React from "react";
import Button from "@material-ui/core/Button";
import "../styles/introduction.css";

const Introduction = (props) => {
  return (
    <div className="introduction-text-wrapper">
      <h3>Bienvenido <strong style={{ fontSize: "2rem", textDecoration: "underline" }}>{props.signatoryName}</strong> al proceso de firma online de tu contrato de arriendo</h3>
      <br />
      <p id="introduction-text">
        Este proceso <strong>no durará más de 5 minutos</strong>.
      </p>
      {/*
      
      <br />
      <p id="property-address">
        El Taihuen 12501, Lo Barnechea, Región Metropolitana
      </p>
      */}
      <p id="property-address" style={{ marginTop: 40 }}>
        Este proceso te permitirá firmar de manera digital el contrato.
      </p>
      <br />
      <p id="property-address">El proceso es el siguiente:</p>
      <ul style={{ fontSize: "1.3rem" }}>
        <li>Usted debe revisar y aprobar el contrato.</li>
        <li>Subir foto de la cédula de identidad por ambos lados.</li>
        <li>Aprobar la firma digital de los documentos.</li>
      </ul>
      <center>
        <Button
          variant="contained"
          color="primary"
          id="commence-process"
          onClick={() => {
            props.trigger("next");
          }}
          style={{ textTransform: "capitalize" }}
        >
          Comenzar proceso de firma
        </Button>
      </center>
    </div>
  );
};

export default Introduction;
