import axios from "axios";
import { LARAVEL_URL, API_URL } from "../env";

class AuthService {
  login(data) {
    const http = axios.create({
      baseURL: API_URL,
    });
    return http.post(`/login/`, data).catch((error) => {
      console.log(error);
      return false;
    });
  }

  recoverPassword(email) {
    const headers = {
      "Content-Type": "application/json",
    };

    const http = axios.create({ baseURL: LARAVEL_URL, headers });
    return http
      .post("/api/v1/recoveraccount", { mail: email })
      .catch((error) => {
        console.log(`peticion con error ${error}`);
        return false;
      });
  }
}

export const authService = new AuthService();
