import React, { Fragment, Component } from "react";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import ReactCrop from "react-image-crop";
import { Row, Col } from "reactstrap";
import queryString from "query-string";
import "react-image-crop/dist/ReactCrop.css";
import "../styles/signature_creation.css";
import LinearProgress from "@material-ui/core/LinearProgress";
import { contractService } from "../../services/contracts.js";
import loadImage from "blueimp-load-image/js";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import Swal from "sweetalert2";

class SignatureCreation extends Component {
  state = {
    src: null,
    pane: 0,
    selectedSignature: null,
    loading: true,
    crop: {
      unit: "%",
      width: 30,
      height: 15,
      x: 20,
      y: 20,
    },
    fetchingSignatures: true,
    hasPreviousSignature: false,
    previousSignatureUrl: "",
    extractedSignatures: [],
    currentCiFrontOrientation: 0,
    ciFrontFile: null,
  };

  executeLoadImage = (file, orientation) => {
    const orientations = [1, 6, 3, 8]; // orientations: 0=1, 1=6, 2=3, 3=8
    loadImage(
      file,
      (img) => {
        var base64data = img.toDataURL(`image/jpeg`);
        this.setState({ src: base64data });
      },
      { orientation: orientations[orientation], canvas: true },
    );
  };

  rotateCiFront = (orientation) => {
    const val = this.state.currentCiFrontOrientation + orientation;
    // circular array index
    const newOrientation = val < 0 ? 3 : val % 4;
    this.setState({ currentCiFrontOrientation: newOrientation });
    this.executeLoadImage(this.state.ciFrontFile, newOrientation);
  };

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (["image/jpeg", "image/png"].includes(e.target.files[0].type)) {
        this.setState({
          pane: 1,
          ciFrontFile: e.target.files[0],
        });

        this.props.setCiFront(e.target.files[0]);
        this.executeLoadImage(e.target.files[0], 0);
      } else {
        Swal.fire({
          icon: "error",
          title: "Formato de archivos incorrecto",
          text: "Solo se aceptan imágenes en formato png o jpg",
        });
      }
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg",
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(crop.width * scaleX);
    canvas.height = Math.ceil(crop.height * scaleY);
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error("Canvas is empty");
            return;
          }
          this.setState({ blob });
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve(this.fileUrl);
        },
        "image/png",
        1,
      );
    });
  }

  async uploadIdentification() {
    return true;
  }

  async extractSignatures() {
    this.setState({
      pane: 3,
      fetchingSignatures: true,
    });
    var formData = new FormData();
    formData.append("ci", this.state.blob);
    const extracted = await contractService.extractSignatures(formData);
    if (extracted) {
      this.setState({
        extractedSignatures: extracted.data.links,
        fetchingSignatures: false,
      });
    }
  }

  async handleProceedToSignature() {
    this.props.setSelectedSignature(this.state.selectedSignature);
    let unusedSignatureKeys = this.state.extractedSignatures.filter((item) => {
      return Object.keys(item)[0] !== this.state.selectedSignature;
    });
    unusedSignatureKeys = unusedSignatureKeys.map((item) => {
      return Object.keys(item)[0];
    });
    await contractService.removeUnusedSignatures({
      keys: unusedSignatureKeys,
    });
    this.props.trigger("next");
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    if (values.pane) {
      this.setState({
        pane: values.pane - 1,
      });
    }
  }

  render() {
    const {
      pane,
      src,
      crop,
      selectedSignature,
      fetchingSignatures,
      extractedSignatures,
      croppedImageUrl,
    } = this.state;

    return (
      <Fragment>
        <p id="introduction-text">Este proceso es más facil en el celular</p>
        <br />
        <Stepper activeStep={pane} orientation="vertical">
          <Step key="Selecciona/toma una foto de tu carnet">
            <StepLabel>
              Sube o toma una foto de la parte <strong>frontal</strong> del
              carnet
            </StepLabel>
            <StepContent>
              <div className="cropper-preview-wrap">
                <input
                  type="file"
                  accept="image/*"
                  onChange={this.onSelectFile}
                />
              </div>
            </StepContent>
          </Step>
          <Step key="Selecciona/toma una foto de tu carnet">
            <StepLabel>
              Sube o toma una foto de la parte <strong>trasera</strong> del
              carnet
            </StepLabel>
            <StepContent>
              <div className="cropper-preview-wrap">
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    this.props.setCiBack(e.target.files[0]);
                    this.setState({
                      pane: 2,
                    });
                  }}
                />
              </div>
            </StepContent>
          </Step>
          <Step key="Selecciona">
            <StepLabel>Selecciona tu firma</StepLabel>
            <StepContent>
              <Row className="mb-3">
                <Col xs="4" sm="5" md="2" lg="1" className="my-auto">
                  Rotar:
                </Col>
                <Col xs="4" sm="2" md="2" lg="1">
                  <Button onClick={() => this.rotateCiFront(-1)}>
                    <RotateLeftIcon />
                  </Button>
                </Col>
                <Col xs="4" sm="2" md="2" lg="1">
                  <Button onClick={() => this.rotateCiFront(1)}>
                    <RotateRightIcon />
                  </Button>
                </Col>
              </Row>
              <ReactCrop
                src={src}
                crop={crop}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
              {croppedImageUrl && (
                <img
                  alt="Crop"
                  style={{ maxWidth: "30%" }}
                  src={croppedImageUrl}
                />
              )}
              <br />
              <center>
                <Button
                  variant="contained"
                  color="primary"
                  id="commence-process"
                  style={{ textTransform: "capitalize" }}
                  onClick={() => {
                    this.extractSignatures();
                  }}
                >
                  Confirmar
                </Button>
              </center>
            </StepContent>
          </Step>
          <Step key="Confirma">
            <StepLabel>¿Cuál de las firmas se ve mejor?</StepLabel>
            <StepContent>
              {!fetchingSignatures ? (
                <Fragment>
                  <center>
                    <Button
                      variant="contained"
                      color="primary"
                      id="go-back"
                      style={{ textTransform: "capitalize" }}
                      onClick={() => this.setState({ pane: 2 })}
                    >
                      Volver a recortar
                    </Button>
                  </center>
                  <br />
                  <Row>
                    {extractedSignatures.map((item, i) => (
                      <Col sm={6} key={i}>
                        <img
                          src={item[Object.keys(item)[0]]}
                          alt="signature-1"
                          onClick={() => {
                            this.setState({
                              selectedSignature: Object.keys(item)[0],
                            });
                          }}
                          className={`hoverable ${
                            Object.keys(item)[0] === selectedSignature
                              ? "selected-signature"
                              : "unselected-signature"
                          }`}
                        />
                      </Col>
                    ))}
                  </Row>
                  <center>
                    <Button
                      variant="contained"
                      color="primary"
                      id="commence-process"
                      style={{ textTransform: "capitalize" }}
                      onClick={async () => {
                        let uploaded = await this.uploadIdentification();
                        if (uploaded) {
                          this.handleProceedToSignature();
                        }
                      }}
                      disabled={!selectedSignature}
                    >
                      Proceder a la firma
                    </Button>
                  </center>
                </Fragment>
              ) : (
                <LinearProgress color="secondary" />
              )}
            </StepContent>
          </Step>
        </Stepper>
      </Fragment>
    );
  }
}

export default withRouter(SignatureCreation);
